import React from 'react';
import { Auth } from '@digsup/patterns-auth';
import { useFormikContext } from '@digsup/components-form';
import { Steps } from '@digsup/components-steps';
import { Text } from '@digsup/components-text';
import { Button } from '@digsup/components-button';

export const SidebarPanel = ({
  passwordValidation,
}: {
  passwordValidation: any;
}) => {
  const { values } = useFormikContext();
  let passwordErrors = [];
  try {
    passwordValidation?.validateSync(values?.password, { abortEarly: false });
  } catch (err) {
    passwordErrors = err?.errors;
  }

  if (passwordErrors?.length === 0 && !values?.termsAccepted) {
    return (
      <Auth.Panel>
        <Auth.Main>
          <Auth.Subtitle icon="user-small">Terms & Conditions</Auth.Subtitle>
          <Auth.Info>
            Please agree to the terms and conditions to proceed.
            <br /> <br />
            The checkbox should appear right below the password field on the
            left.
          </Auth.Info>
        </Auth.Main>
        <Auth.Footer>
          <Text className="u-margin-bottom-xsmall">Need help?</Text>
          <Button
            type="button"
            variant="tertiary"
            width="default"
            href="mailto:support@digsup.com?subject=DigsUp%20Support%20Request">
            Contact Support
          </Button>
        </Auth.Footer>
      </Auth.Panel>
    );
  }
  const isDone = condition =>
    passwordErrors?.includes(condition) ? 'wait' : 'done';

  return (
    <Auth.Panel>
      <Auth.Main>
        <Auth.Subtitle icon="padlock-locked">
          Password Requirements
        </Auth.Subtitle>
        <div className="u-display-flex u-justify-content-center">
          <Steps variant="outlined" spacing="small">
            <Steps.Item
              title="At leat 8 characters long"
              status={isDone('length')}
              className="u-margin-bottom-medium"
            />
            <Steps.Item
              title="Uppercase letters"
              status={isDone('uppercase')}
              className="u-margin-bottom-medium"
            />
            <Steps.Item
              title="Lowercase letters"
              status={isDone('lowercase')}
              className="u-margin-bottom-medium"
            />
            <Steps.Item
              title="Numbers"
              status={isDone('number')}
              className="u-margin-bottom-medium"
            />
            <Steps.Item
              title="Punctuation"
              status={isDone('punctuation')}
              className="u-margin-bottom-medium"
            />
          </Steps>
        </div>
      </Auth.Main>
      <Auth.Footer>
        <Text className="u-margin-bottom-xsmall">Need help?</Text>
        <Button
          type="button"
          variant="tertiary"
          width="default"
          href="mailto:support@digsup.com?subject=DigsUp%20Support%20Request">
          Contact Support
        </Button>
      </Auth.Footer>
    </Auth.Panel>
  );
};
