import React from 'react';
import { Auth } from '@digsup/patterns-auth';

import { TAuthenticationState } from '../authentication-types';

import { EmailStep } from './email-step';
import { LoginPasswordStep } from './login-password-step';
import { SignupPasswordStep } from './signup-password-step';
import { SignupUserInfoStep } from './signup-user-info-step';

type AuthenticationModalProps = {
  onLogin: ({ password, username }, { setErrors }) => void;
  isOpen: boolean;
  invalidLoginAttempts: number;
  authState: TAuthenticationState;
  onCheckEmail: ({ username }) => void;
  openSignUpModal: () => void;
  onSignUpUserInfo: (values) => void;
  onClose: () => void;
  onPasswordReset: (username) => void;
  onPrev: (toStep) => void;
  onSignUp: (values) => void;
};

export const AuthenticationModal = ({
  onClose,
  isOpen,
  authState,
  onCheckEmail,
  openSignUpModal,
  onLogin,
  onPrev,
  onSignUpUserInfo,
  onSignUp,
  invalidLoginAttempts,
  onPasswordReset,
  ...props // need to unpack this
}: AuthenticationModalProps) => (
  <Auth.Modal isOpen={isOpen} onClose={onClose} {...props}>
    {authState?.currentStep === 'email' && (
      <EmailStep
        onSubmit={onCheckEmail}
        initialValues={authState?.values}
        isAnimEnter={false}
      />
    )}
    {authState?.currentStep === 'login-password' && (
      <LoginPasswordStep
        onSubmit={onLogin}
        prev={() => onPrev('email')}
        initialValues={authState?.values}
        onPasswordReset={onPasswordReset}
        invalidLoginAttempts={invalidLoginAttempts}
      />
    )}
    {authState?.currentStep === 'sign-up-user-info' && (
      <SignupUserInfoStep
        onSubmit={onSignUpUserInfo}
        initialValues={authState?.values}
        prev={() => onPrev('email')}
      />
    )}
    {authState?.currentStep === 'sign-up-password' && (
      <SignupPasswordStep
        onSubmit={onSignUp}
        prev={() => onPrev('sign-up-user-info')}
        initialValues={authState?.values}
      />
    )}
  </Auth.Modal>
);
