import React from 'react';
import { Button } from '@digsup/components-button';
import { Auth } from '@digsup/patterns-auth';
import { useFormikContext } from '@digsup/components-form';

type SidebarPanelProps = {
  isAnimEnter: boolean;
  onPasswordReset: (username: string) => void;
}

export const SidebarPanel = ({
  isAnimEnter,
  onPasswordReset,
}: SidebarPanelProps) => {
  const { errors } = useFormikContext();
  return (
    <Auth.Panel isAnimEnter={isAnimEnter}>
      {errors?.password && (
        <>
          <Auth.Main>
            <Auth.Subtitle icon="face-sad">Invalid Password</Auth.Subtitle>
            <Auth.Info>Password incorrect</Auth.Info>
          </Auth.Main>
          <Auth.Footer>
            <Button
              variant="tertiary"
              width="default"
              type="button"
              onClick={onPasswordReset}>
              Reset Password
            </Button>
          </Auth.Footer>
        </>
      )}
      {!errors?.password && (
        <>
          <Auth.Main>
            <Auth.Subtitle icon="information">Learn More</Auth.Subtitle>
            <Auth.Features>
              <Auth.FeaturesItem icon="privacy">
                Advanced privacy protection. Browse homes and communicate
                anonymously.
              </Auth.FeaturesItem>
              <Auth.FeaturesItem icon="search">
                The most advanced home search engine. Find homes before they hit
                the market.
              </Auth.FeaturesItem>
              <Auth.FeaturesItem icon="reach">
                Reach out to homeowners even if they aren&apos;t on DigsUp.
              </Auth.FeaturesItem>
            </Auth.Features>
          </Auth.Main>
        </>
      )}
    </Auth.Panel>
  );
};
