import React from 'react';
import { Form, Yup } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';

import { EmailPanel } from './email-panel';
import { SidebarPanel } from './sidebar-panel';
import { AuthInitialValues } from '../../authentication-types';

type EmailStepProps = {
  isAnimEnter: boolean;
  initialValues: AuthInitialValues;
  onSubmit: ({ username }: { username: string }) => void;
};

export const EmailStep = ({
  initialValues,
  onSubmit,
  ...props
}: EmailStepProps) => {
  const formSchema = Yup.object().shape({
    username: Yup.string().email('Invalid Email').required('Required'),
  });
  return (
    <Form
      tooltipProps={{
        zIndex: 'modal',
      }}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmit}>
      {() => (
        <Auth.Step>
          <EmailPanel {...props} />
          <SidebarPanel isAnimEnter={true} />
        </Auth.Step>
      )}
    </Form>
  );
};
