import React from 'react';
import { Auth } from '@digsup/patterns-auth';
import { Password } from '@digsup/components-input';
import { Text } from '@digsup/components-text';
import { Link } from '@digsup/components-link';
import Recaptcha from 'react-google-recaptcha';
import { Form, useFormikContext } from '@digsup/components-form';

type LoginPasswordPanelProps = {
  prev: () => void;
  isAnimEnter: boolean;
};

export const LoginPasswordPanel = ({
  prev,
  isAnimEnter,
}: LoginPasswordPanelProps) => {
  const { errors } = useFormikContext();
  return (
    <Auth.Panel type="primary" isAnimEnter={isAnimEnter}>
      <Auth.Main>
        <Auth.Title>Log in</Auth.Title>
        <Form.Field name="password">
          {({ field, meta }) => (
            // eslint-disable-line react/prop-types
            <Password
              {...field}
              error={!!meta.touched && !!meta.error}
              size="medium"
              autoComplete="current-password"
              className="u-margin-bottom-small"
            />
          )}
        </Form.Field>
        {errors.recaptcha && (
          <Form.Field name="recaptcha">
            {({ field, form }) => (
              // eslint-disable-line react/prop-types
              <Recaptcha
                sitekey={process?.env?.NEXT_PUBLIC_ReCAPTCHA_SITE_KEY}
                render="explicit"
                onChange={response => form.setFieldValue(field?.name, response)}
              />
            )}
          </Form.Field>
        )}
        <Auth.Controls>
          <Auth.ControlBtn type="prev" onClick={prev}>
            Return back
          </Auth.ControlBtn>
          <Auth.ControlBtn>Login</Auth.ControlBtn>
        </Auth.Controls>
      </Auth.Main>
      <Auth.Footer>
        <Text size="6xs" align="center" color="theme-descriptive">
          See our{' '}
          <Link href="https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href="https://app.termly.io/document/privacy-policy/9276ef06-a5e3-4c1b-b01d-cebd004a8180">
            Privacy Policy
          </Link>
        </Text>
      </Auth.Footer>
    </Auth.Panel>
  );
};
