import React from 'react';
import { Form } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';
import { Password } from '@digsup/components-input';
import { Text } from '@digsup/components-text';
import { Link } from '@digsup/components-link';
import { Checkbox } from '@digsup/components-checkbox';
import Recaptcha from 'react-google-recaptcha';

export const SignupPasswordPanel = ({ prev }: { prev: () => void }) => (
  <Auth.Panel type="primary">
    <Auth.Main>
      <Auth.Title>Create Password</Auth.Title>
      <Form.Field name="password">
        {({ field, meta }) => (
          <Password
            {...field}
            error={!!meta?.touched && !!meta?.error}
            size="medium"
            autoComplete="new-password"
            className="u-margin-bottom-small"
          />
        )}
      </Form.Field>
      <Form.Field name="recaptcha">
        {({ field, form }) => (
          <Recaptcha
            sitekey={process.env.NEXT_PUBLIC_ReCAPTCHA_SITE_KEY}
            render="explicit"
            onChange={response => form.setFieldValue(field?.name, response)}
          />
        )}
      </Form.Field>
      <Form.Field name="termsAccepted">
        {({ field, meta, form }) => (
          <Checkbox
            size="medium"
            className="u-margin-bottom-small"
            {...field}
            checked={field?.value}
            value={true}
            onChange={event =>
              form.setFieldValue(field?.name, !!event?.target?.checked)
            }
            hasError={!!meta?.touched && !!meta?.error}>
            I have read the{' '}
            <Link href="https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37">
              Terms of Service
            </Link>
          </Checkbox>
        )}
      </Form.Field>
      <Auth.Controls>
        <Auth.ControlBtn type="prev" onClick={prev}>
          Return back
        </Auth.ControlBtn>
        <Auth.ControlBtn>Create Account</Auth.ControlBtn>
      </Auth.Controls>
    </Auth.Main>
    <Auth.Footer>
      <Text size="6xs" align="center" color="theme-descriptive">
        See our{' '}
        <Link href="https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://app.termly.io/document/privacy-policy/9276ef06-a5e3-4c1b-b01d-cebd004a8180">
          Privacy Policy
        </Link>
      </Text>
    </Auth.Footer>
  </Auth.Panel>
);
