import React from 'react';
import { Form, Yup } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';

import { SidebarPanel } from './sidebar-panel';
import { LoginPasswordPanel } from './login-password-panel';
import { AuthInitialValues } from '../../authentication-types';

type LoginPasswordStepProps = {
  initialValues: AuthInitialValues;
  onPasswordReset: (username: string) => void;
  invalidLoginAttempts: number;
  prev: () => void;
  onSubmit: (
    { password, username }: { password: string; username: string },
    { setErrors }: { setErrors: unknown },
  ) => void;
};

export const LoginPasswordStep = ({
  initialValues,
  onSubmit,
  onPasswordReset,
  invalidLoginAttempts,
  prev,
}: LoginPasswordStepProps) => (
  <Form
    tooltipProps={{
      zIndex: 'modal',
    }}
    validateOnBlur={false}
    initialValues={initialValues}
    validationSchema={Yup.object().shape({
      password: Yup.string().required('Required'),
      recaptcha: invalidLoginAttempts >= 2 && Yup.string().required('Required'),
    })}
    onSubmit={({ password }, formik) => {
      onSubmit({ password, username: initialValues.username }, formik);
    }}>
    {() => (
      <Auth.Step>
        <LoginPasswordPanel isAnimEnter={true} prev={prev} />
        <SidebarPanel
          isAnimEnter={false}
          onPasswordReset={() => onPasswordReset(initialValues.username)}
        />
      </Auth.Step>
    )}
  </Form>
);
