import React from 'react';
import { SocialMediaButton } from '@digsup/components-button';
import { Form } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';
import { Input } from '@digsup/components-input';
import { Icon } from '@digsup/components-icon';
import { Text } from '@digsup/components-text';
import { Link } from '@digsup/components-link';

export const EmailPanel = props => (
  <Auth.Panel type="primary" {...props}>
    <Auth.Main>
      <Auth.Title>Log in | Join</Auth.Title>
      <Form.Field name="username" className="u-margin-bottom-large">
        {(
          { field, meta }, // eslint-disable-line react/prop-types
        ) => (
          <Input
            {...field}
            error={!!meta?.touched && !!meta?.error}
            addonAfter={
              <Auth.ControlBtn
                icon={[
                  <Icon
                    key="icon"
                    color="currentColor"
                    name="arrow-right"
                    shape="none"
                    size="medium"
                  />,
                ]}
                iconOnly
                size="medium"
                variant="brand-default"
              />
            }
            placeholder="Enter your email"
            size="medium"
            type="email"
            autoComplete="email"
          />
        )}
      </Form.Field>
      <Text
        size="7xs"
        fontWeight="semibold"
        align="center"
        color="theme-descriptive"
        marginBottom="large"
        className="u-visuallyhidden">
        OR
      </Text>
      <SocialMediaButton
        provider="facebook"
        type="button"
        onClick={() => {
          window.location.href = `${process?.env?.NEXT_PUBLIC_BACKEND_ENDPOINT}/auth/facebook`;
        }}
        className="u-margin-bottom-small u-visuallyhidden">
        Continue with Facebook
      </SocialMediaButton>
      <SocialMediaButton
        provider="google"
        type="button"
        className="u-margin-bottom-small u-visuallyhidden"
        onClick={() => {
          window.location.href = `${process?.env?.NEXT_PUBLIC_BACKEND_ENDPOINT}/auth/google`;
        }}>
        Continue with Google
      </SocialMediaButton>
      <SocialMediaButton
        provider="twitter"
        type="button"
        className="u-margin-bottom-small u-visuallyhidden"
        onClick={() => {
          window.location.href = `${process?.env?.NEXT_PUBLIC_BACKEND_ENDPOINT}/auth/twitter`;
        }}>
        Continue with Twitter
      </SocialMediaButton>
    </Auth.Main>
    <Auth.Footer>
      <Text size="6xs" align="center" color="theme-descriptive">
        See our{' '}
        <Link href="https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://app.termly.io/document/privacy-policy/9276ef06-a5e3-4c1b-b01d-cebd004a8180">
          Privacy Policy
        </Link>
      </Text>
    </Auth.Footer>
  </Auth.Panel>
);
