import React from 'react';
import { Button } from '@digsup/components-button';
import { Auth } from '@digsup/patterns-auth';
import { Text } from '@digsup/components-text';

export const SidebarPanel = () => (
  <Auth.Panel>
    <Auth.Main>
      <Auth.Subtitle icon="user-small">COPPA &amp; Privacy</Auth.Subtitle>
      <Auth.Info>
        In compliance with the Children&apos;s Online Privacy and Protection
        Act, this service is not available to children.
        <br /> <br />
        Your name and profile photo (if you choose to use one) will only be
        revealed to other users when you choose to make contact with them.
      </Auth.Info>
    </Auth.Main>
    <Auth.Footer>
      <Text className="u-margin-bottom-xsmall">Need help?</Text>
      <Button
        type="button"
        variant="tertiary"
        width="default"
        href="mailto:support@digsup.com?subject=DigsUp%20Support%20Request">
        Contact Support
      </Button>
    </Auth.Footer>
  </Auth.Panel>
);
