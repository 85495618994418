import React from 'react';
import { Form, Yup } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';

import { SidebarPanel } from './sidebar-panel';
import { SignupUserInfoPanel } from './signup-user-info-panel';
import { AuthInitialValues } from '../../authentication-types';

type SignupUserInfoStepProps = {
  prev: () => void;
  initialValues: AuthInitialValues;
  onSubmit: (values: any) => void;
};

export const SignupUserInfoStep = ({
  onSubmit,
  initialValues,
  ...props
}: SignupUserInfoStepProps) => {
  const formSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dateOfBirth: Yup.string().required('Required'),
    invitationCode: Yup.string(),
  });
  return (
    <Form
      tooltipProps={{
        zIndex: 'modal',
      }}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmit}>
      {() => (
        <Auth.Step>
          <SignupUserInfoPanel {...props} />
          <SidebarPanel />
        </Auth.Step>
      )}
    </Form>
  );
};
