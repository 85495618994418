import React from 'react';
import { Form, Yup } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';

import { SidebarPanel } from './sidebar-panel';
import { SignupPasswordPanel } from './signup-password-panel';
import { AuthInitialValues } from '../../authentication-types';

type SignupPasswordStepProps = {
  prev: () => void;
  initialValues: AuthInitialValues;
  onSubmit: (values: unknown) => void;
};

export const SignupPasswordStep = ({
  prev,
  onSubmit,
  initialValues,
  ...props
}: SignupPasswordStepProps) => {
  const passwordValidation = Yup.string()
    .min(8, 'length')
    .matches(/[a-z]/, 'lowercase')
    .matches(/[A-Z]/, 'uppercase')
    .matches(/[1-9]/, 'number')
    .matches(/[^a-zA-Z0-9-]/, 'punctuation')
    .required('required');
  const formSchema = Yup.object().shape({
    password: passwordValidation,
    termsAccepted: Yup.bool().oneOf([true], 'Must be approved'),
    recaptcha: Yup.string().required('Required'),
  });

  return (
    <Form
      tooltipProps={{
        zIndex: 'modal',
        tooltipShown: false,
      }}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmit}>
      {() => (
        <Auth.Step>
          <SignupPasswordPanel {...props} prev={prev} />
          <SidebarPanel passwordValidation={passwordValidation} />
        </Auth.Step>
      )}
    </Form>
  );
};
