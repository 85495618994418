import React from 'react';
import { Form } from '@digsup/components-form';
import { Auth } from '@digsup/patterns-auth';
import { Input } from '@digsup/components-input';
import { Text } from '@digsup/components-text';
import { Link } from '@digsup/components-link';
import { Cell, Grid } from '@digsup/components-grid';
import { DatePicker } from '@digsup/components-datepicker';

export const SignupUserInfoPanel = ({ prev }: { prev: () => void }) => (
  <>
    <Auth.Panel type="primary">
      <Auth.Main>
        <Auth.Title marginBottom="medium">Create Account</Auth.Title>
        <Text
          size="7xs"
          fontWeight="semibold"
          align="start"
          marginBottom="small">
          Enter Name
        </Text>
        <Grid gutter="xsmall" className="u-margin-bottom-xlarge">
          <Cell columns="6">
            <Form.Field name="firstName">
              {(
                { field, meta }, // eslint-disable-line react/prop-types
              ) => (
                <Input
                  error={meta?.touched && !!meta?.error}
                  {...field}
                  placeholder="First Name"
                  autoComplete="given-name"
                />
              )}
            </Form.Field>
          </Cell>
          <Cell columns="6">
            <Form.Field name="lastName">
              {(
                { field, meta }, // eslint-disable-line react/prop-types
              ) => (
                <Input
                  {...field}
                  error={meta?.touched && !!meta?.error}
                  placeholder="Last Name"
                  autoComplete="family-name"
                />
              )}
            </Form.Field>
          </Cell>
        </Grid>
        <Text
          size="7xs"
          fontWeight="semibold"
          align="start"
          marginBottom="small">
          Enter Date of Birth
        </Text>
        <Form.Field
          name="dateOfBirth"
          className="u-margin-bottom-xlarge-stretched">
          {(
            { field, form, meta }, // eslint-disable-line react/prop-types
          ) => (
            <DatePicker
              {...field}
              error={meta?.touched && !!meta?.error}
              initialFocusYearOption={new Date().getFullYear() - 30}
              onChange={value =>
                form.setFieldValue(field?.name, value?.toISOString?.())
              }
            />
          )}
        </Form.Field>
        <Text
          size="7xs"
          fontWeight="semibold"
          align="start"
          marginBottom="small">
          Invite code? Enter here if so:
        </Text>
        <Form.Field
          name="invitationCode"
          className="u-margin-bottom-medium-stretched">
          {(
            { field, form, meta }, // eslint-disable-line react/prop-types
          ) => (
            <Input
              {...field}
              error={meta?.touched && !!meta?.error}
              placeholder="Optional"
            />
          )}
        </Form.Field>
        <Auth.Controls>
          <Auth.ControlBtn type="prev" onClick={prev}>
            Return back
          </Auth.ControlBtn>
          <Auth.ControlBtn type="next">Choose Password</Auth.ControlBtn>
        </Auth.Controls>
      </Auth.Main>
      <Auth.Footer>
        <Text size="6xs" align="center" color="theme-descriptive">
          See our{' '}
          <Link href="https://app.termly.io/document/terms-of-use-for-saas/00e9ad1e-22f6-478e-b4d4-f426fdee2c37">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href="https://app.termly.io/document/privacy-policy/9276ef06-a5e3-4c1b-b01d-cebd004a8180">
            Privacy Policy
          </Link>
        </Text>
      </Auth.Footer>
    </Auth.Panel>
  </>
);
