import React from 'react';
import { Auth } from '@digsup/patterns-auth';

export const SidebarPanel = ({ isAnimEnter }: { isAnimEnter: boolean }) => (
  <Auth.Panel isAnimEnter={isAnimEnter}>
    <Auth.Main>
      <Auth.Subtitle icon="information">Learn More</Auth.Subtitle>
      <Auth.Features>
        <Auth.FeaturesItem icon="privacy">
          Advanced privacy protection. Browse homes and communicate anonymously.
        </Auth.FeaturesItem>
        <Auth.FeaturesItem icon="search">
          The most advanced home search engine. Find homes before they hit the
          market.
        </Auth.FeaturesItem>
        <Auth.FeaturesItem icon="reach">
          Reach out to homeowners even if they aren&apos;t on DigsUp.
        </Auth.FeaturesItem>
      </Auth.Features>
    </Auth.Main>
  </Auth.Panel>
);
