import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ErrorNames } from '@digsup/constants';
import { useAppContext, useAuthentication } from '@/fe-hooks';
import { AppContextType } from '@/fe-common/types/contexts';
import { toast } from '@digsup/components-toast';
import { Text } from '@digsup/components-text';
import { Homepage } from '@digsup/templates-homepage';

import { AuthInitialValues, TAuthenticationState } from './authentication-types';
import { AuthenticationModal } from './components';

export type AuthenticationContainerProps = {
  isOpen: boolean;
  onClose?: () => void;
  isOpenSignUpInvite?: boolean;
  onCloseSignUpInvite?: () => void;
  openAuthModal?: () => void;
  hasRoutingSkipAfterLogin?: boolean;
  returnToPath?: string;
  invitationToken?: string;
};

type TInitialLogin = {
  username?: string;
  password?: string;
  usedEmail?: string;
  invitationCode?: string;
};

const initialState: TAuthenticationState = {
  currentStep: 'email',
  values: {
    username: '',
    firstName: '',
    lastName: '',
    invitationCode: '',
    dateOfBirth: '',
    password: '',
    usedEmail: false,
    usedInviteCode: false,
    termsAccepted: false,
  },
};

export const AuthenticationContainer = ({
  onClose,
  invitationToken,
  returnToPath,
  hasRoutingSkipAfterLogin = false,
  isOpen,
  openAuthModal,
  isOpenSignUpInvite = false,
  onCloseSignUpInvite,
}: AuthenticationContainerProps) => {
  const router = useRouter();
  const { setAuthInfo }: AppContextType = useAppContext();

  const {
    invalidLoginAttempts,
    checkEmailUsed,
    checkInvitationTokenValid,
    logInUser,
    signUpUser,
    sendPasswordResetEmail,
  } = useAuthentication();

  const [invitationTokenChecked, setInvitationTokenChecked] = useState(false);
  const [invitationTokenValid, setInvitationTokenValid] = useState(false);
  const [authState, setAuthState] =
    useState<TAuthenticationState>(initialState);
  const [isOpenSignUpModal, setIsOpenSignUpModal] =
    useState(isOpenSignUpInvite);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(isOpen);

  useEffect(() => {
    if (invitationToken && !invitationTokenChecked) {
      checkInvitationTokenValid({
        variables: {
          invitationToken,
        },
      })
        .then(({ data }) => {
          const username = data?.checkInvitationTokenValid?.tokenEmail;
          setAuthState((state: TAuthenticationState) => ({
            ...state,
            values: {
              ...state.values,
              username,
            },
          }));
          setInvitationTokenValid(true);
          setInvitationTokenChecked(true);
        })
        .catch(() => {
          setInvitationTokenChecked(true);
          setInvitationTokenValid(false);
          toast(<Text>This invitation link is not valid anymore.</Text>, {
            theme: 'important',
          });
        });
    }
  }, [invitationToken, invitationTokenChecked, checkInvitationTokenValid]);

  useEffect(() => {
    setIsOpenSignUpModal(isOpenSignUpInvite);
  }, [isOpenSignUpInvite]);

  useEffect(() => {
    setIsAuthModalOpen(isOpen);
  }, [isOpen]);

  const updateAuthState = (
    nextStep: string,
    data: TInitialLogin = { usedEmail: null, password: null },
  ) => {
    setAuthState((state: TAuthenticationState) => ({
      currentStep: nextStep,
      values: { ...state.values, ...data } as AuthInitialValues,
    }));
  };

  const onLoginHandler = ({ password, username }, { setErrors }) =>
    logInUser({
      username,
      password,
    })
      .then(res => {
        const { data } = res;
        const { user, onboardingCompleted } = data?.logInUser || {};
        setAuthInfo({ userId: user?.id, accountId: user?.accountId });

        if (!hasRoutingSkipAfterLogin) {
          if (onboardingCompleted) {
            router.push(returnToPath || '/dashboard');
          } else {
            router.push('/welcome');
          }
        }
        onClose?.();
      })
      .catch(error => {
        const graphQLError = error.graphQLErrors?.[0];
        if (graphQLError?.message === ErrorNames.PasswordIncorrectError) {
          setErrors({ password: 'Password is incorrect' });
        }
      });

  const onCheckEmailHandler = ({ username }) =>
    checkEmailUsed({
      variables: { email: username },
    }).then(({ data }) => {
      const values = { usedEmail: data?.checkEmailUsed, username };
      if (values.usedEmail) {
        updateAuthState('login-password', values);
      } else {
        updateAuthState('sign-up-user-info', values);
      }
    });

  const onPasswordResetHandler = username => {
    sendPasswordResetEmail({
      variables: {
        username,
      },
    }).then(({ data }) => {
      if (data?.sendPasswordResetEmail) {
        toast(<Text>Password reset email sent to  {username}</Text>, {
          // eslint-disable-next-line react/prop-types
          theme: 'positive',
        });
      }
    });
  };

  const onSignUpHandler = values =>
    signUpUser({
      ...authState?.values,
      ...values,
      invitationToken,
    }).then(({ data }) => {
      const { user } = data?.signUpUser || {};
      setAuthInfo({ userId: user?.id, accountId: user?.accountId });
      router.push('/welcome');
    });

  const onCloseAuthModalForAll = () => {
    setIsAuthModalOpen(false);
    onClose?.();
    updateAuthState('email');
  };

  const onCloseSignUpModalForAll = () => {
    setIsOpenSignUpModal(false);
    onCloseSignUpInvite();
  };

  if (invitationToken && !invitationTokenChecked) return null;

  return (
    <>
      <AuthenticationModal
        onLogin={onLoginHandler}
        isOpen={isAuthModalOpen}
        invalidLoginAttempts={invalidLoginAttempts}
        onCheckEmail={onCheckEmailHandler}
        authState={authState}
        openSignUpModal={() => {
          setIsOpenSignUpModal(true);
          onCloseAuthModalForAll();
        }}
        onSignUpUserInfo={values => updateAuthState('sign-up-password', values)}
        onClose={onCloseAuthModalForAll}
        onPasswordReset={onPasswordResetHandler}
        onPrev={toStep => updateAuthState(toStep)}
        onSignUp={onSignUpHandler}
      />
      <Homepage.SignUpModal
        isOpen={isOpenSignUpModal}
        onClose={onCloseSignUpModalForAll}
        onLogIn={() => {
          setIsOpenSignUpModal(false);
          // to verify "digsup "code otherwise no need for this.
          openAuthModal();
        }}
      />
    </>
  );
};
